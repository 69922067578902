<template>
  <iframe :src="webUrl+'/admin/stock/list/batch.all'"></iframe>
</template>

<script>
export default {
  name: "stock.list.batch.all",
  emits : [ 'setMenuKey' ],
  setup(props, { emit }){
    emit('setMenuKey', 'dj.dbjkc');
    return {
      "webUrl" : process.env.VUE_APP_DAILIAO_WEB_URL
    }
  }
}
</script>

<style scoped>

</style>